:root {
  --menu-icon-width: 3.5rem;
}

#menu-open-cb {
  visibility: hidden;
  position: absolute;
  left: -4000em;
  width: 0;
  height: 0;
}

.header {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  background: var(--white);
  color: var(--text);
  box-shadow: var(--shadow);

  display: flex;
  flex-flow: column nowrap;

  overflow: hidden;
  transition: width 0.5s easeOutExpo;
}

body {
  padding-left: calc(var(--menu-icon-width) + 1rem);
  padding-top: 5rem;
  padding-bottom: 2rem;
}

.header {
  width: var(--menu-icon-width);

  .do-close {
    display: none;
  }
}

.menu {
  display: flex;
  flex-flow: column nowrap;
}

.menu__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1.25rem 0 1rem 0;
  transition: filter 0.2s;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  transition: color 0.1s;
}

.menu__item--centered {
  justify-content: center;
}

.menu__item.active,
.menu__item:hover {
  background: var(--gray);
  color: var(--white);
}

.menu__icon {
  flex: 0 0 var(--menu-icon-width);
  text-align: center;
  position: relative;
}

.menu__icon small {
  display: block;
  text-decoration: underline;
  font-size: 10px;
}

.menu__icon.notify::before {
  content: "";
  position: absolute;
  top: -2px;
  right: 14px;
  width: 6px;
  height: 6px;
  border-radius: 9px;
  background: var(--texas-rose);
  box-shadow: 0 0 0 2px var(--white);
}

.menu__item:hover .notify::before,
.menu__item.active .notify::before {
  box-shadow: 0 0 0 2px var(--gray);
}

.menu__item::after {
  content: attr(title);
  opacity: 0;
  transition: opacity 0.5s easeOutExpo;
  margin-top: -0.3rem; /* Why? */
}

@media (--media-sm-only) {
  body {
    padding-left: 0;
    padding-top: 5rem;
  }

  .menu-open-overlay {
    display: none;
  }

  .header {
    position: absolute;
    bottom: auto;
    right: 0;
    width: auto;
    padding: 0 1rem;
    display: block;
  }

  .menu {
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0;
  }

  .menu__item {
    padding: 0.5rem;
  }

  .menu__icon {
    flex: 1 0 auto;
  }

  .menu__item i {
    margin: 0;
  }

  .menu--toggle,
  .menu--meta,
  .menu__item::after {
    display: none;
  }
}

@media (--media-md) {
  .menu--main {
    margin-top: 2rem;
  }

  .menu--meta {
    margin-top: auto;
  }

  .menu-open-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;

    background: rgba(0, 0, 0, 0.3);
    pointer-events: none;
    transition: opacity 0.5s easeOutExpo;
    opacity: 0;
  }

  #menu-open-cb:checked ~ .menu-open-overlay {
    pointer-events: all;
    opacity: 1;
  }

  #menu-open-cb:checked ~ .header {
    width: 14rem;

    .do-close {
      display: inline;
    }
    .do-open {
      display: none;
    }

    .menu__item::after {
      opacity: 1;
    }
  }
}

@media (--media-xl) {
  .header {
    width: 14rem;
  }

  body {
    padding-left: 16rem;
  }

  .menu--toggle {
    pointer-events: none;
    opacity: 0;
  }

  .menu-open-overlay {
    display: none;
  }

  .menu__item::after {
    opacity: 1;
  }
}

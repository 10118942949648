.attachments {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.attachments__attachment {
  border: var(--hairline);
  padding: 0.75rem 1rem 0.75rem 0.5rem;
  text-decoration: none;

  color: inherit;
  transition:
    background 0.2s,
    color 0.2s;

  display: flex;
  align-items: center;
  gap: 0.25rem;

  .icon {
    --size: 1.5rem;
  }

  img {
    max-width: min(100%, 100px);
  }

  &:hover {
    background: var(--black);
    color: var(--white);
  }
}

.attachments__attachment--primary {
  grid-column: 1 / -1;

  img {
    max-width: none;
    max-height: 500px;
  }
}

.attachments__attachment-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 25ch;
}

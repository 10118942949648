.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.text-right {
  text-align: right !important;
}

.no-style {
  color: inherit;
  text-decoration: none;
}

.hover-underline:hover {
  text-decoration: underline;
}

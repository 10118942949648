.login-container {
  display: grid;
  place-items: center;
  min-height: 80vh;
}

.login {
  width: min(80vw, 26rem);
  min-height: min(80vh, 10rem);

  h1 {
    margin-bottom: 1rem;
  }

  h1,
  .buttons {
    text-align: center;
  }

  .button {
    flex: 1 0 100%;
    background: transparent;
    color: inherit;
  }

  .button:hover {
    background: var(--green);
    color: var(--text);
  }

  .container {
    padding: 0;
  }
}

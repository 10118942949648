.textlink {
  font-size: var(--text-textlink);
  font-weight: var(--weight-medium);
  color: var(--blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.textlink::before {
  content: "";
  background: currentColor;
  border-radius: 0 99px 99px 0;
  display: inline-block;
  position: relative;
  top: -0.05em;
  width: 0.3em;
  height: 0.6em;
  margin-right: 0.5em;
}

.textlink--cog {
  color: var(--black);
  background: url(svg/cog.svg) no-repeat left center;
  background-size: 1em;
  display: inline-block;
  text-indent: 1.2em;

  &::before {
    display: none;
  }
}

dialog::backdrop {
  /* native */
  background-color: rgba(226, 226, 226, 0.6);
}
dialog + .backdrop {
  /* polyfill */
  background-color: rgba(226, 226, 226, 0.6);
}

dialog {
  border: none;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 30, 0.3);
  max-width: 40rem;
  padding: 0;
}

.modal {
  padding: 1rem;
}

.modal--type {
  border: 1px solid var(--bg-color);

  h3 {
    color: var(--txt-color, var(--bg-color));
  }
}

.modal__title {
  padding-right: 2rem;
}

.modal__title,
.modal__body {
  margin-bottom: 1rem;
}

.modal__footer .button {
  margin-bottom: 0;
}

button.close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border: var(--hairline);
  border-radius: 99px;

  &:hover {
    opacity: 0.5;
  }

  span {
    font-size: 1.75rem;
    position: relative;
    top: -10px;
    left: -2px;
  }
}

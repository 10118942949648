:root {
  /* Grays */
  --myst: #f0f0f0;
  --gray: #5b5d55;
  --lightgray: #bbb;
  --text: #000;
  --darkblack: #111;
  --black: #333;
  --white: #fff;

  /* Colors */
  --green: #d9de50;
  --cyan: #6ff199;
  --sludge: #a3a925;
  --blue: #7777ff;

  /* New colors */
  --starship: #d6df23;
  --starship-tp: #d6df2328;
  --canary: #e0fe53;
  --de-york: #6bba77;
  --pale-green: #92eda0;
  --hippie-blue: #628f9c;
  --half-baked: #8fb7c1;
  --texas-rose: #eea941;
  --texas-rose-tp: #eea94128;
  --carnation-pink: #e998b6;
  --indigo: #4678d9;
  --indigo-tp: #4678d920;
  --medium-purple: #9877f7;
  /* End */

  --link: #5555ff;
  --link-hover: #7777ff;

  --error: #ff0117;
  --success: #7abe5c;
  --warning: #ff6000;
  --info: #000;

  --shadow: 0 0 4px 0 rgba(50, 50, 100, 0.4);
  --hairline: 1px solid rgba(0, 0, 0, 0.2);
}

body {
  color: var(--text);
  background: var(--white);
  overflow-y: scroll;
}

.profile {
  border-radius: 100px;
}

.accent-green {
  --accent: var(--green);
}
.accent-cyan {
  --accent: var(--cyan);
}
.accent-sludge {
  --accent: var(--sludge);
}
.accent-blue {
  --accent: var(--blue);
}

.type--birthday {
  --bg-color: var(--indigo);
  --tp-color: var(--indigo-tp);
  --icon: url("svg/calendar/birthday.svg");
}

.type--work-anniversary {
  --bg-color: var(--medium-purple);
  --icon: url("svg/calendar/work-anniversary.svg");
}

.type--milestone {
  --bg-color: var(--pale-green);
  --icon: url("svg/calendar/milestone.svg");
}

.type--warranty {
  --bg-color: var(--carnation-pink);
  --icon: url("svg/calendar/warranty.svg");
}

.type--vacation,
.type--holiday {
  --bg-color: var(--half-baked);
  --fg-color: #fff;
  --icon: url("svg/calendar/vacation.svg");
}

.type--absent {
  --bg-color: var(--hippie-blue);
  --fg-color: #fff;
  --icon: url("svg/calendar/vacation.svg");
}

.type--homeoffice {
  --bg-color: var(--de-york);
  --fg-color: #fff;
  --icon: url("svg/calendar/homeoffice.svg");
}

.type--event {
  --bg-color: var(--starship);
  --tp-color: var(--starship-tp);
  --fg-color: var(--black);
  --hover-color: #d6df2348;
  --hairline: 1px solid var(--bg-color);
  --txt-color: #bbc318;

  button.close {
    border-color: var(--bg-color);
    background: var(--bg-color);
    color: var(--white);
  }
}

.type--public_holiday {
  --bg-color: var(--de-york);
  --fg-color: #fff;
}

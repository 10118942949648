.filter {
  display: flex;
  margin-bottom: 1rem;

  .submenu {
    margin-bottom: 0;
  }
}

.filter__title {
  margin-bottom: 1rem;
}

.filter__form {
  margin: 0 0 0 auto;
}

.form--filter {
  display: flex;
  align-items: stretch;
  gap: 0.3rem;

  > * {
    margin-right: 0;
  }

  input,
  select {
    max-width: 10rem;
  }

  .button {
    padding: 0.3rem 0.6rem;
  }
}

.form input[type="text"].filter__search {
  background: url(svg/magnifying-glass.svg) no-repeat 0.5em center;
  padding-left: 2.5em;
  min-width: 20rem;
}

@media (--media-sm-only) {
  .filter {
    flex-flow: column nowrap;
  }

  .filter__form {
    margin: 0;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    text-align: left;
    vertical-align: top;
    padding: 0 1rem 0.5rem 1px;
  }
}

.table--striped {
  tbody > tr:nth-child(2n + 1) {
    td,
    th {
      background: var(--myst);
    }
  }
}

.table--details {
  width: 100%;

  td {
    padding-left: 0;
  }

  td:nth-child(1) {
    font-weight: var(--weight-medium);
  }
}

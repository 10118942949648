.announcements {
  margin-bottom: 1rem;
}

.announcement {
  border-top: var(--hairline);
  border-bottom: var(--hairline);
  margin-top: -1px;
  background: var(--white);
}

.announcement--unread {
  color: var(--white);
  background: var(--blue);
}

.announcement__summary {
  padding: 0.5rem 1rem;
  /*
  Safari doesn't understand display:grid on <summary> elements at the time of
  writing. This is why we're using div inside the summary element.
  */
  display: flex;
  align-items: center;
  cursor: pointer;

  transition: background 0.2s;

  .announcement:not(.announcement--unread) &:hover {
    background: var(--myst);
  }
}

.announcement__title {
  flex: 1 1 auto;
  font-size: var(--text-subtitle);
}

.announcement__date {
  padding: 0 1rem;
}

.announcement__icons {
  white-space: nowrap;
  text-align: right;
  flex: 0 1 2.5rem;
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
  align-items: center;
}

.announcement--starred .announcement__icons .icon--unstar {
  display: none;
}

.announcement--unstarred .announcement__icons .icon--star {
  display: none;
}

.announcement__details {
  border-top: 1px solid var(--white);
  padding: 0.5rem 1rem 1rem 1rem;
}

@media (--media-md) {
  .announcement__body {
    max-width: 80ch;
  }
}

@media (--media-sm-only) {
  .announcement__summary {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .announcement__title {
    flex: 1 0 100%;
  }

  .announcement__date {
    padding-left: 0;
  }
}

.announcement .attachments {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

:root {
  --text-hero: 1.5rem;
  --text-title: 1.25rem;
  --text-subtitle: 1rem;
  --text-lead: 1rem; /* 16px */
  --text-p: 0.875rem; /* 14px */
  --text-section: 0.875rem; /* 14px */
  --text-textlink: 0.875rem; /* 14px */
  --text-button: 0.8125rem; /* 13px */
  --text-tiny: 0.625rem; /* 10px */

  --text-small-title: 0.875rem; /* 14px */
  --text-small-p: 0.75rem; /* 12px */

  --weight-regular: 400;
  --weight-medium: 500;
  --weight-bold: 700;
}

@media (--media-xl) {
  :root {
    --text-hero: 1.75rem; /* 28px */
    --text-title: 1.375rem; /* 22px */
    --text-subtitle: 1rem; /* 16px */
  }
}

body {
  font-family: "Poppins", sans-serif;
  font-size: var(--text-p);
  overflow-y: scroll;
}

.text-hero,
h1 {
  font-size: var(--text-hero);
  font-weight: var(--weight-regular);
  margin-bottom: 1em;
}

.text-title,
h2 {
  font-size: var(--text-title);
  font-weight: var(--weight-regular);
}

.text-subtitle,
h3 {
  font-size: var(--text-subtitle);
  font-weight: var(--weight-regular);
}

h4,
h5,
h6 {
  font-size: var(--text-p);
}

.content {
  h1,
  h2,
  h3,
  p,
  ol,
  ul {
    margin-bottom: 1rem;
  }

  a {
    color: var(--link);
  }

  a:hover {
    color: var(--link-hover);
  }
}

.dateline {
  text-transform: uppercase;
  font-size: var(--text-small-p);
}

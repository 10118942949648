.usercal {
  --grid-gutter: 1.5rem;
}

.usercal__title {
  border-bottom: var(--hairline);
  font-size: var(--text-button);
  text-transform: uppercase;
}

.usercal__month {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(7, 2rem);
  gap: 0.125rem;
}

.usercal__cell {
  text-align: center;
  display: grid;
  place-items: center;
  position: relative;
  transition: 0.2s background;
  border-radius: 4px;
}

.usercal__text {
  --size: 1.75em;
  display: inline-block;
  height: var(--size);
  width: var(--size);
  line-height: var(--size);
  border-radius: 999px;
  position: relative;
  transition:
    opacity 0.2s,
    background 0.2s;
}

.usercal__icon {
  background-image: var(--icon);
  width: 8px;
  height: 8px;
  position: absolute;

  --outside: -3px;

  &:nth-child(1) {
    top: var(--outside);
    right: var(--outside);
  }

  &:nth-child(2) {
    bottom: var(--outside);
    right: var(--outside);
  }

  &:nth-child(3) {
    top: var(--outside);
    left: var(--outside);
  }

  &:nth-child(4) {
    bottom: var(--outside);
    left: var(--outside);
  }

  &.type--vacation,
  &.type--absent,
  &.type--holiday,
  &.type--homeoffice {
    display: none;
  }
}

.usercal__cell--weekday {
  font-weight: bold;
}

.usercal__cell--thisweek {
  background: var(--myst);
}

.usercal__cell--thisweek + .usercal__cell--thisweek::before {
  content: "";
  background: var(--myst);
  position: absolute;
  width: 8px;
  top: 0;
  bottom: 0;
  left: -4px;
}

.usercal__cell--vacation .usercal__text,
.usercal__cell--absent .usercal__text {
  background: #53919e;
  color: #fff;
}

.usercal__cell:hover .usercal__text {
  background: var(--lightgray);
}

.usercal__cell--vacation:hover .usercal__text,
.usercal__cell--absent:hover .usercal__text {
  background: #53919e;
  opacity: 0.5;
}

.usercal__cell--homeoffice .usercal__text {
  background: #5b5d55;
  color: #fff;
}

.usercal__popup {
  position: absolute;
  z-index: 1;
  background: var(--white);
  border: var(--hairline);
  border-radius: 4px;
  min-width: 10rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  bottom: 85%;
  left: 0;
  text-align: left;

  display: none;

  .usercal__cell:hover & {
    display: block;
  }
}

.usercal__event {
  position: relative;
  padding-left: 8px;

  &::before {
    content: "";
    background: var(--icon) no-repeat;
    width: 10px;
    height: 10px;
    position: absolute;
    left: -4px;
    top: 6px;
  }
}

.usercal__event + .usercal__event {
  margin-top: 0.75rem;
  border-top: var(--hairline);
  padding-top: 0.75rem;
}

.cal {
  --row-height: 2rem;
  --header-height: 6rem;
  /* 1px at the top and bottom and a 1px border */
  --event-height: calc(var(--row-height) - 3px);
  /* --day-width: 1.5rem; */
  --day-width: 1rem;
  --user-width: 12rem;

  margin-right: 1rem;

  margin-top: -1rem;
  border-top: var(--hairline);
  padding-top: 2rem;
}

.cal__types {
  display: flex;
  gap: 1rem;
  margin-left: var(--user-width);
  margin-bottom: 1rem;
  font-size: var(--text-small-p);
}

.cal__calendar {
  position: relative;
  overflow: scroll;
}

.cal__type {
  cursor: pointer;
  transition:
    font-weight 0.1s,
    filter 0.1s,
    opacity 0.1s;

  &::before {
    display: inline-block;
    content: "";
    width: 0.75em;
    height: 0.75em;
    margin-right: 0.25em;
    background: var(--bg-color);
    border-radius: 10px;
  }

  &:hover {
    text-decoration: underline;
  }

  &.active {
    font-weight: bold;
  }

  &.inactive {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

.cal__users {
  position: sticky;
  padding-top: var(--header-height);
  left: 0;
  width: var(--user-width);
  background: white;
  z-index: 3;
  border-right: var(--hairline);
}

.cal__user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

.cal__events {
  background: repeating-linear-gradient(
    to right,
    var(--myst) 0,
    var(--myst) calc(var(--day-width) * 7),
    transparent calc(var(--day-width) * 7),
    transparent calc(var(--day-width) * 14)
  );
  position: absolute;
  left: var(--user-width);
  top: 0;
  width: calc(var(--day-width) * var(--days));
}

.cal__header-row {
  height: var(--header-height);
  position: sticky;
  top: 0;
  z-index: 3;
  width: calc(var(--day-width) * var(--days));

  background: repeating-linear-gradient(
    to right,
    var(--myst) 0,
    var(--myst) calc(var(--day-width) * 7),
    #fff calc(var(--day-width) * 7),
    #fff calc(var(--day-width) * 14)
  );
}

.cal__row {
  position: relative;
  border-top: var(--hairline);
  height: calc(var(--height, 1) * var(--row-height));
}

.cal__row--no-borders {
  border-top-color: transparent;
}

.cal__row--zero {
  background-color: var(--texas-rose-tp);
}

.cal__cell {
  position: absolute;
  left: calc(var(--start) * var(--day-width) + 1px);
  width: calc((1 + var(--days)) * var(--day-width) - 2px);
  top: 0;
  bottom: 0;

  color: inherit;
  text-decoration: none;
}

.cal__header-cell {
  position: absolute;
  bottom: 0;
  padding: 0 0.125rem;
  font-size: var(--text-small-p);
}

.cal__user {
  line-height: var(--event-height);
  padding: 0 0.25rem;
  margin-top: 1px;
}

.cal__event {
  font-size: var(--text-small-p);
  background: var(--bg-color);
  color: var(--fg-color, #000);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 1px;

  height: var(--event-height);
  line-height: var(--event-height);
  padding: 0 0.25rem;
  border-radius: 4px;
}

.cal__popup {
  display: none;
  min-width: 15rem;
  max-width: 25rem;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 95%;
  background: var(--white);
  border: var(--hairline);
  padding: 0.5rem;

  h4 {
    border-bottom: var(--hairline);
  }

  .cal__event:hover + &,
  &:hover {
    display: block;
  }
}

.cal__today {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: calc((0.4 + var(--today)) * var(--day-width) + 1px);
  height: calc(var(--header-height) + var(--rows) * var(--row-height));
  width: 1px;
  background: #5b5d55;
}

@media (--media-sm-only) {
  .cal-wrapper {
    margin-left: var(--grid-gutter);
  }

  .cal {
    --user-width: 6rem;
  }

  .cal__users {
    overflow: hidden;
  }

  .cal__types {
    flex-flow: row wrap;
    margin: 0 1rem 1rem;
  }
}

.dt-accent-0 {
  --accent: #5b5c54;
}
.dt-accent-1 {
  --accent: var(--pale-green);
}
.dt-accent-2 {
  --accent: var(--carnation-pink);
}
.dt-accent-3 {
  --accent: var(--indigo);
}
.dt-accent-4 {
  --accent: var(--texas-rose);
}
.dt-accent-5 {
  --accent: var(--de-york);
}
.dt-accent-6 {
  --accent: var(--half-baked);
}
.dt-accent-7 {
  --accent: var(--canary);
}

.boxes.categories {
  margin-bottom: 5rem;
}

.categories .box__body {
  min-height: 5rem;
}

.category {
  text-decoration: none;
  color: inherit;
}

.category .box__body {
  transition:
    background-color 0.2s,
    border-color 0.2s,
    color 0.2s;
}

.category:hover .box__body,
.category--active .box__body {
  background: var(--black);
  color: var(--white);
  border-color: var(--black);
}

.documents__category {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.documents__topic {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.df__type {
  margin-right: 1rem;
}

.df__type input {
  display: none;
}

.df__type label {
  cursor: pointer;
  opacity: 0.4;
  filter: grayscale(30%);
  transition: all 0.2s;
}

.df__type :checked + label,
.df__type.checked label,
.df__type label:hover {
  opacity: 1;
  filter: none;
}

.df__type label {
  border-bottom: 3px solid var(--accent);
}

.types,
.phases {
  margin-bottom: 1rem;
}

.phases {
  display: flex;
  gap: 0.25rem 0;
  flex-flow: row wrap;
}

.types__title,
.phases__title {
  display: block;
  flex: 0 0 100%;
}

.phases input {
  display: none;
}

.phase label {
  display: inline-block;
  position: relative;
  padding: 0.5rem 0.25rem 0.5rem 0.75rem;
  background: var(--label-bg);
  color: var(--black);
  cursor: pointer;

  transition: 0.2s;

  --label-bg: var(--myst);
  height: 38px;

  svg {
    position: absolute;
    top: 0;
    height: 38px;
    right: -21px;
    color: var(--label-bg);
    transition: 0.2s;
  }
}

.phase label:hover {
  --label-bg: var(--lightgray);
}

.phase input:checked + label {
  --label-bg: var(--gray);
  color: var(--white);

  .phase__sia {
    background: var(--myst);
    color: var(--black);
  }
}

.phase__sia {
  background: var(--gray);
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  font-size: 0.75rem;
  color: var(--white);
  border-radius: 99px;
  margin-right: 0.25rem;
}

.phase + .phase label {
  padding-left: 2rem;
}

.document .box__body {
  background: var(--myst);
  border: none;
  position: relative;
  padding-bottom: 0.5rem;

  min-height: 8rem;

  display: grid;
  grid-template-columns: 1fr max-content max-content;
  grid-template-rows: max-content auto max-content 1.5rem;
  grid-template-areas:
    "title title star"
    "keywords keywords keywords"
    "phases phases phases"
    "updated-on download download";

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 3px;
    border-radius: 4px;
    background: var(--accent);
  }
}

.document__title {
  grid-area: title;
  font-size: var(--text-small-title);
  overflow: hidden;
}

.document__keywords {
  grid-area: keywords;
  font-size: var(--text-small-p);
  margin-bottom: 1rem;
}

.document__phases {
  grid-area: phases;

  display: flex;
  gap: 0.125rem;
  font-size: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: var(--hairline);
  margin-bottom: 0.5rem;
}

.document__phase {
  width: 1.75em;
  height: 1.75em;
  line-height: 1.75em;
  display: inline-block;
  border-radius: 999px;
  text-align: center;
  vertical-align: middle;

  &.on {
    background: var(--gray);
    color: var(--white);
  }

  &.off {
    background: var(--white);
    color: var(--text);
  }
}

.document__updated-on {
  grid-area: updated-on;

  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--text-tiny);
  font-weight: var(--weight-regular);

  border-right: var(--hairline);
}

.document__download {
  grid-area: download;

  position: relative;
  z-index: 1;

  display: grid;
  place-items: center;

  padding: 0 0.75rem;
  transition:
    background-color 0.1s,
    color 0.1s;

  svg {
    width: 1.125em;
    height: 1.125em;
  }

  &:hover {
    background: black;
    color: white;
  }
}

.document__star {
  grid-area: star;
  cursor: pointer;

  position: relative;
  z-index: 1;
}

.form {
  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="text"],
  select:not([multiple]),
  textarea {
    display: block;
    width: 100%;
    border: 1px solid var(--lightgray);
    border-radius: 4px;

    padding: 0.25rem 0.5rem;

    background: var(--white);
    color: var(--black);

    &:focus {
      box-shadow: 0 0 0 2px rgba(100, 120, 254, 0.25);
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  input[type="checkbox"] {
    margin-left: 1px;
  }

  .widget--radioselect > ul,
  .widget--checkboxselectmultiple > ul {
    list-style: none;
    padding: 0;
    margin-top: 0;
  }

  .errors {
    display: flex;
    align-items: center;
    line-height: 1.3;

    padding: 0.5rem;
    border: 1px solid;
    border-radius: 999px;
    margin-bottom: 1rem;

    color: var(--error);

    i {
      font-size: 2em;
    }
  }

  .errors--warning {
    color: var(--warning);
  }

  .errors__text {
    margin-left: 0.5rem;
  }

  .field {
    margin-bottom: 1rem;

    > label {
      display: inline-block;
      margin-bottom: 0.25rem;
    }

    > label.required::after {
      content: "*";
      opacity: 0.6;
    }
  }

  .is-invalid {
    > label {
      color: var(--error);
    }

    input,
    select,
    textarea {
      border-color: var(--error);
    }
  }

  .field__errors {
    color: var(--error);
  }
}

.form--search {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 3rem;
  gap: 1rem;

  input {
    max-width: 16rem !important;
  }

  button {
    margin: 0;
  }
}

.formset {
  border: var(--hairline);
  margin-bottom: 1rem;
  padding: 1rem;
}

.formset--oneline {
  display: flex;
  gap: 1rem;
  flex-flow: row nowrap;
  align-items: flex-end;

  .widget--checkboxinput {
    white-space: nowrap;
  }
}

.box-line {
  color: var(--black);
  text-align: center;
  position: relative;
  border-top: var(--hairline);
  margin-top: 1rem;
  font-size: var(--text-section);
  font-weight: var(--weight-regular);
  text-transform: uppercase;
}

.box-line__text {
  position: relative;
  display: inline-block;
  background: var(--white);
  padding: 0 1.25em;
  top: -0.8em;
  margin-bottom: 1rem;
}

.boxes {
  grid-auto-flow: row dense;
}

.box {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.box__overlay-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.box__body {
  padding: 1rem;
  border: var(--hairline);
  border-radius: 4px;
  flex: 1 0 auto;
  position: relative;
  min-height: 7rem;
}

.box__body h2,
.box__title {
  margin-bottom: 0.5rem;
  font-size: var(--text-title);
  overflow-wrap: break-word;
}

.box__body h2 a {
  color: inherit;
}

.box__body a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.box--has-link .box__body {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.box__link {
  display: block;
  padding: 0.75rem 1rem;
  font-size: var(--text-button);
  font-weight: var(--weight-medium);
  letter-spacing: 0.05em;

  text-align: center;
  text-decoration: none;

  background: var(--green);
  color: var(--text);

  transition:
    background 0.1s,
    border-color 0.1s,
    color 0.1s;

  &:hover {
    background: var(--lightgray);
    color: var(--black);
  }
}

.box__tools {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.box__tools a {
  text-decoration: none;
  color: var(--blue);
  font-size: 1.5rem;

  border-radius: 99px;
  display: inline-grid;
  place-items: center;
  width: 0.8em;
  height: 0.8em;
  padding: 0;
  margin: 0;

  transition: background 0.2s;
}

.box__tools a:hover {
  background: var(--lightgray);
}

.box__tools svg {
  fill: currentColor;
}

.box__toolbar {
  border-top: var(--hairline);
  display: flex;
  justify-content: space-between;
}

.box__tool {
  --size: 1em;
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  background: transparent;
  color: var(--blue);
  border: none;
  margin-top: 1em;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;

  transition: opacity 0.1s;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.box__tool--left {
  background-image: url("svg/chevron-left.svg");
}

.box__tool--right {
  background-image: url("svg/chevron-right.svg");
}

.box--objects {
  .box__body {
    padding-bottom: 0.5rem;
  }

  .object:last-child {
    border-bottom: none;
  }

  .hello__image,
  .hello__initials {
    width: 2rem;
    height: 2rem;
  }
}

.box--black {
  .box__body {
    background: #5b5d55;
    color: var(--white);
  }

  .box__tools a {
    background: var(--green);
    color: var(--white);
  }

  .box__tools a:hover {
    background: var(--lightgray);
  }

  .object {
    color: inherit;
    border-color: #bbb !important;
  }

  .dateline {
    color: #b0b0b0;
  }
}

.box--image {
  .box__body {
    background-size: cover;
  }
}

.box--type {
  .box__body {
    background-color: var(--tp-color);
    border-color: var(--bg-color);
  }

  h2,
  h3 {
    color: var(--txt-color, var(--bg-color));
  }

  h3 {
    margin-top: 0.5rem;
  }
}

.buttons {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin: 1.5rem 0;
}

.button {
  border: none;
  display: inline-block;
  vertical-align: middle;
  font-size: inherit;
  user-select: none;
  line-height: 1.5;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 1rem 1rem 0;
  color: var(--black);
  background-color: var(--green);
  /* border: 1px solid; */
  cursor: pointer;

  font-size: var(--text-button);
  font-weight: var(--weight-medium);

  transition:
    0.2s background,
    0.2s border-color,
    0.2s color;

  &:hover {
    background-color: var(--lightgray);
    /* border-color: var(--green); */
  }

  &[disabled],
  &[disabled]:hover {
    opacity: 0.5;
    cursor: not-allowed;
    color: var(--black);
    background-color: var(--white);
    border-color: currentColor;
  }

  /*
  Unfortunately, Safari doesn't support gap:1rem on flex items (yet), otherwise
  we could stop using margins to create *gap*s between buttons.
  */
  &.ml-auto,
  &.ml-auto + .button {
    margin-left: 1rem;
    margin-right: 0;
  }
}

.button--delete {
  background: var(--warning);
  color: var(--white);
}

.button--clear {
}

.button--small {
  text-transform: none;
  padding: 0.125rem 0.5rem;
}

h1 .button--small {
  margin: 0 0 0.3rem 1rem;
}

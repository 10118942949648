.strengths {
  min-height: 10rem;
}

.strength,
.badge--strength {
  cursor: pointer;
}

.badge--strength {
  transition: background 0.2s;

  &:hover {
    background: var(--lightgray);
  }
}

.strength {
  display: block;
  position: absolute;
  inset: 0;
  padding: 1rem;
  background: var(--blue);
  color: var(--white);
}

.textlink--strength {
  display: block;
  margin-top: 0.5rem;
}

.message {
  display: flex;
  align-items: center;
  line-height: 1.3;

  padding: 0.5rem;
  border: 1px solid;
  border-radius: 999px;
  margin-bottom: 0.5rem;

  &.error {
    color: var(--error);
  }

  &.success {
    color: var(--success);
  }

  &.warning {
    color: var(--warning);
  }

  &.info {
    color: var(--blue);
  }
}

.message i {
  font-size: 2em;
}

.message__text {
  margin-left: 0.5rem;
}

.submenu {
  display: block;
  margin-top: -0.5rem;
  font-weight: normal;
  font-size: var(--text-p);
  margin-bottom: 2rem;
}

.submenu__item {
  color: var(--lightgray);
  font-weight: bold;
  text-decoration: none;
  margin-right: 2rem;

  &:hover,
  &.active {
    color: var(--black);
    text-decoration: underline;
  }
}

.badge {
  font-size: var(--text-p);
  font-weight: var(--weight-regular);
  display: inline-block;
  padding: 0.25em 0.5em;
  margin: 0 0.25em 0.5em 0;
}

.badge--blue {
  background: var(--blue);
  color: var(--white);
}

.badge--cyan {
  background: var(--cyan);
}

.badge--green {
  background: var(--green);
}

.icon {
  --size: 1.2em;
  display: inline-block;
  height: var(--size);
  width: var(--size);
  background-repeat: no-repeat;
  background-size: contain;
}

.icon--pin {
  background-image: url(svg/pin.svg);
}

.icon--clip {
  background-image: url(svg/clip.svg);
}

.icon--star {
  background-image: url(svg/star.svg);
}

.icon--unstar {
  background-image: url(svg/unstar.svg);
}

.icon-wrap {
  position: relative;
  display: inline-grid;
  place-items: relative;
}

.icon-wrap .icon {
  position: relative;
  z-index: 1;
}

.icon-wrap:not(.icon-wrap--nohover)::before {
  content: "";
  position: absolute;
  width: 23px;
  height: 23px;
  top: -3px;
  left: -3px;

  background-color: var(--lightgray);
  border-radius: 99px;

  transition: opacity 0.2s;
  opacity: 0;
}

.icon-wrap:hover::before {
  opacity: 1;
}

[data-starred="yes"] .icon--unstar,
[data-starred="no"] .icon--star {
  display: none;
}

.box__body .icon {
}

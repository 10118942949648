.object {
  display: flex;
  align-items: center;
  padding: 0.5rem 1px;
  border-bottom: var(--hairline);

  color: var(--black);
  text-decoration: none;

  &:first-child {
    border-top: var(--hairline);
  }
}

a.object:hover {
  background: var(--hover-color, var(--myst));
}

.object--spread {
  justify-content: space-between;
}

.object--stack {
  flex-flow: column nowrap;
  align-items: flex-start;
}

.object--top {
  align-items: flex-start;
}

.object .hello__image,
.object .hello__initials {
  margin-right: 1rem;
}

.object__summary {
  cursor: pointer;
  padding: 1rem 0;
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.object__content {
  font-weight: normal;
  padding-top: 1rem;
}

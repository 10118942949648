.contact-details {
  border-top: var(--hairline);
  padding-top: 1rem;
  margin-bottom: 1rem;

  display: flex;
  flex-flow: row wrap;
  gap: 1rem;

  a {
    color: inherit !important;
  }

  strong {
    display: block;
  }
}

.cards-title {
  margin-bottom: 1rem;

  .cards + & {
    margin-top: 3rem;
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(var(--cards-columns, 1), minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 512px) {
  .cards {
    --cards-columns: 2;
  }
}

@media (--media-md) {
  .cards {
    --cards-columns: 3;
  }
}

@media (--media-lg) {
  .cards {
    --cards-columns: 4;
  }
}

.card {
  display: flex;
  flex-flow: column nowrap;

  color: inherit;
  text-decoration: none;
}

.card__image {
  min-height: 6rem; /* crappy devices without aspect-ratio support */
  aspect-ratio: 16/9;
  background-size: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card__image--none {
  background: linear-gradient(135deg, var(--green), var(--myst));
}

.card__body {
  padding: 0.5rem 0.75rem;
  border: var(--hairline);
  border-top: none !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  flex: 1 0 auto;

  display: flex;
  flex-flow: column nowrap;

  transition:
    background 0.2s,
    border-color 0.2s,
    color 0.2s;
}

.card:hover .card__body {
  border-color: var(--black);
  background: var(--black);
  color: var(--white);
}

.card__title {
  font-size: var(--text-subtitle);
  margin-bottom: 0.75rem;
}

.card__excerpt {
  flex: 1 0 auto;
  margin-bottom: 0.75rem;

  font-size: var(--text-p);
}

.card {
  position: relative;

  .icon-wrap {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

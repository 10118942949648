.hello {
  display: flex;
  align-items: center;

  position: absolute;
  top: 1rem;
  right: 1rem;

  color: inherit;
  text-decoration: none;

  i {
    color: var(--black);
    background: var(--green);
    border-radius: 999px;
    padding: 0.125rem;
    margin-right: 0.25rem;
  }
}

.hello__text {
  display: inline-block;
  margin-right: 0.375rem;
}

.hello__image {
  width: 2.5rem;
  border-radius: 100px;
  display: inline-block;
}

.hello__initials {
  display: inline-grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100px;
  background: var(--accent);
}

@media (--media-sm-only) {
  .hello__wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .hello {
    position: static;
    display: flex;
    gap: 0.375rem;
  }

  .hello__text {
    order: 1;
  }
}

.facepile {
  display: flex;
  flex-flow: row wrap-reverse;
  gap: 0.25rem;

  .hello__image,
  .hello__initials {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 80%;
  }
}

.profile {
  margin-bottom: 1rem;
  display: grid;
  place-items: center;

  .hello__image,
  .hello__initials {
    width: 4em;
    height: 4em;
    font-size: var(--text-hero);

    margin-bottom: 0.25rem;
  }
}

.profile__links {
  text-align: center;
  margin-top: -1.5rem;
  margin-bottom: 2rem;
}
